/*
 * 实物促销
 */
import TablePage from '@/found/components/table_page';
import Form from './form/index.js';

export default {
  name: 'promote_employee_salaries',
  extends: TablePage,
  components: { Form },
  data() {
    return {};
  },
  methods: {
    modalClick({ val, row }) {
      if (val.code === 'view') {
        this.formName = 'Form';
        this.modalConfig.title = '详情';
        this.openFull();
        this.formConfig = {
          code: val.code,
          row,
        };
      }
      console.log(val.code);
    },
  },
  created() {
    this.getConfigList('promote_employee_salaries');
  },

};
